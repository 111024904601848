body {
  margin: 0px;
  padding: 0px;
}

.auth-screen {
  font-family: 'Raleway', sans-serif;
}

div.form-item {
  margin-bottom: 4px;
}

div.form-group {
  display: flex;
  width: 100%;  
  padding: 5px;
  border-radius: 6px;
}

label.form-label {
  width: 87px;
  height: 37px;
  border-radius: 3px;
  padding: 3px 6px;
  border: 1px solid #C8CED3;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #F0F3F5;
  color: #73818F;
  border-right-width: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
}

div.form-control {
  height: 35px;
  border-radius: 3px;
  border-left-width: 0px;
  border: 1px solid #C8CED3;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #ffffff;
  color: #73818F;
  flex-grow: 1;
  overflow: hidden;
}

div.form-control > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-indent: 12px;
  background-color: #ffffff;
}

div.form-item.has-error > div.form-group {
  background-color: #fee0e0;
  color: #f73130;
}

div.form-item.has-error div.form-control, div.form-item.has-error label.form-label {
  border-color: #fba6a7;
  color: #f73130;
}

div.form-item.has-error label.form-label {
  background-color: #fdd7d5;
}

div.form-item.has-error div.form-control > input {
  background-color: #fdeaea;
  color: #fba6a7;
}

div.form-item.has-error .form-help {
  color: #f73130;
}

.form-help {
  display: flex;
  align-items: center;
  margin: 5px 12px;
}

.form-help > svg {
  margin-right: 4px;
  font-size: 18px;
}

/*
.btn {
  background-color: #cccccc;
  color: #ffffff;
  padding: 7px 12px;  
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  border: none;  
}

a.btn {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
}

.btn.btn-primary {
  border: 1px solid #0084EE;
  background-color: #0084EE;
  color: #ffffff;
}

.btn.btn-primary:hover {
  background-color: #ffffff;
  color: #0084EE;
}

.btn.btn-secondary {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #0084EE;
}

.btn.btn-secondary:hover {
  border: 1px solid #0084EE;
}

.btn.btn-danger {
  border: 1px solid #ff4c4c;
  background-color: #ff4c4c;
  color: #ffffff;
}

.btn.btn-danger:hover {
  color:#ff4c4c;
  background-color: #ffffff
}

.btn.btn-danger-outlined {
  border: 1px solid #ff4c4c;
  background-color: #ffffff;
  color: #ff4c4c;
}

.btn.btn-danger-outlined:hover {
  border: 1px solid #ff4c4c;
  background-color: #ffffff;
  color: #ff4c4c;
}
*/

.btn-group .btn {
  margin-left: 8px;
}

.btn-group .btn:first-child {
  margin-left: 0px;
}

.btn-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-content > .MuiSvgIcon-root {
  margin-right: 4px;
  font-size: 14px;
}

.btn-large > .btn-content > .MuiSvgIcon-root {
  font-size: 24px;
}

.btn-small > .btn-content > .MuiSvgIcon-root {
  font-size: 12px;
}

.btn.disabled {
  opacity: 0.75;
  pointer-events: none;
}


/* Updates */
div.form-control {
  height: 37px;
}

div.form-username-box {
  border: 1px solid rgba(0,0,0,0.045);
  border-radius: 3px;
  padding: 5px;
  background-color: rgba(0,0,0,0.015);
  font-family: arial;
  margin-bottom: 3px;
}